
.content-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .header {
        display: flex;
        justify-content: flex-end;
        i {
            font-size: 12px;
            margin-right: 5px;
        }
    }
    .content-table {
        width: 100%;
        margin-top: 20px;
        flex: 1;
    }
}
